import React from "react";
import styles from "./About.module.css"



function About() {
  return (
    <section id="about" className={styles.container}>

        <h2 className={styles.title}>About</h2>
        <div className={styles.content}>
          <ul className={styles.aboutItems}>
            <li className={styles.aboutItem}>
              <img className={styles.aboutItemImg} src="/assets/about/uiIcon.png" alt="cursor icon"></img>
              <div className={styles.aboutItemText}>
                <h3>Frontend Developer</h3>
                <p>Im a frontend developer with experience in building responsive websites using React.</p>
              </div>
            </li>
            <li className={styles.aboutItem}><img className={styles.aboutItemImg} src="/assets/about/software.png" alt="server icon"></img>
              <div className={styles.aboutItemText}>
                <h3>Software Developer</h3>
                <p>I have experience creating desktop applications using languages such as C#, Java, and C++.</p>
              </div>
            </li>
            
          </ul>
        </div>

    </section>
  );
}

export default About;
