import React from "react";
import styles from "./Contact.module.css";


function Contact() {
  return (
    <footer id="contact" className={styles.container}>

      <div className={styles.text}>
        <h2>Contact</h2>
        <p>Feel free to reach out!</p>
      </div>

      <ul className={styles.links}>
        <li className={styles.link}>
          <img src={"/assets/contact/emailIcon.png"} alt="email icon" />
          <a href="mailto:shipmang8@gmail.com">Email</a>
        </li>
        <li className={styles.link}>
          <img src={"/assets/contact/linkedinIcon.png"} alt="linkedIn icon" />
          <a href="https://www.linkedin.com/in/george-shipman-969a262b0/">LinkedIn</a>
        </li>
        <li className={styles.link}>
          <img src={"/assets/contact/githubIcon.png"} alt="Github icon" />
          <a href="https://github.com/george0815">Github</a>
        </li>
      </ul>

    </footer>
  );
}

export default Contact;
