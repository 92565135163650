import {React, useState} from "react";
import styles from "./Navbar.module.css"


function Navbar() {


const [menuOpen, setMenuOpen] = useState(false)


  return (
    <nav className={styles.navbar}>
      <a className={styles.title} href="/">Portfolio</a>
      <div className={styles.menu}>
        <img  className={styles.menuBtn} src={!menuOpen ? "/assets/nav/menuIcon.png" : "../assets/nav/closeIcon.png"} onClick={(() =>{setMenuOpen(!menuOpen)})} alt={"Menu Button"}/>
        <ul className={`${styles.menuItems} ${menuOpen && styles.menuOpen}`} onClick={(() => {setMenuOpen(false)})}>
            <li><a href="#about">About</a></li>
            <li><a href="#experience">Experience</a></li>
            <li><a href="#projects">Projects</a></li>
            <li><a href="#contact">Contact</a></li>
        </ul>        
      </div>
    </nav>
    
  );
}

export default Navbar;
