import React from "react";
import skills from "../data/skills.json"
import history from "../data/history.json"
import styles from "./Experience.module.css";

function Experience() {
  return (
    <section className={styles.container} id="experience">
        <h2 className={styles.title}>Experience</h2>
        <div className={styles.content}>   
            <div className={styles.skills}>
                {skills.map((skill, id) =>{
                    
                    return <div className={styles.skill} key={id}>
                        <div className={styles.skillImgContainer}>
                            <img src={skill.imageSrc} alt={skill.title}></img>
                        </div>
                        <p>{skill.title}</p>
                    </div>

                })} 
            </div>  
            <ul className={styles.history}>{history.map((historyItem, id)=>{

                return <li className={styles.historyItem} key={id}>
                    <img src={"../assets/" + historyItem.imageSrc} alt={historyItem.organisation}></img>
                    <div className={styles.historyItemDetails}>
                        <h3>{`${historyItem.role}, ${historyItem.organisation}`}</h3>
                        <p>{`${historyItem.startDate}, ${historyItem.endDate}`}</p>
                        <ul>{historyItem.experiences.map((experience, id)=>{
                            return <li key={id}>{experience}</li>
                        })}
                        </ul>
                     </div>
            
                </li>

            })}</ul>
        </div>

    </section>

  );
}

export default Experience;
