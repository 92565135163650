import React from "react";
import styles from "./Hero.module.css";

function Hero() {
  return (
    <section className={styles.container}>
        <div className={styles.content}>
            <h1 className={styles.title}>Hi! I'm George</h1>
            <p className={styles.description}>I'm a programmer with experience in both frontend and desktop application development.</p>
            <a className={styles.contactBtn} href="mailto:shipmang8@gmail.com">Contact Me</a>
        </div>
        <img src="/assets/hero/heroImage.png" className={styles.heroImg} alt="Me"></img>
        <div className={styles.topBlur} ></div>
        <div className={styles.bottomBlur} ></div>

    </section>
  )
}

export default Hero;